import { inputAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    parts.keys,
)

// base
const baseStyle = definePartsStyle(({ theme }) => {
    const colors = theme.colors

    return {
        field: {
            _placeholder: { color: colors.captions.placeholder },
            color: colors.green.default,
        },
    }
})

// sizes
const size = {
    md: defineStyle({
        px: '24px',
        py: '14px',
        height: '56px',
        fontSize: '16px',
        lineHeight: '26px',
        _placeholder: {
            fontSize: '16px',
            lineHeight: '26px',
        },
    }),
    sm: defineStyle({
        px: '20px',
        py: '11px',
        height: '44px',
        fontSize: '14px',
        lineHeight: '22px',
        borderRadius: '10px',
        _placeholder: {
            fontSize: '14px',
            lineHeight: '22px',
        },
    }),
}

const sizes = {
    md: definePartsStyle({
        field: size.md,
        addon: size.md,
    }),
    sm: definePartsStyle({
        field: size.sm,
        addon: size.sm,
    }),
}

// variants
const variants = {
    filled: definePartsStyle(({ theme }) => {
        const colors = theme.colors

        return {
            field: {
                border: '1px solid',
                borderRadius: '10px',
                borderColor: colors.bg['green10'],
                backgroundColor: colors.beige.highlighted,
                _hover: {
                    borderColor: colors.captions.disabled,
                    backgroundColor: colors.beige.highlighted,
                },
                _focusVisible: {
                    borderColor: colors.green.default,
                    backgroundColor: colors.beige.highlighted,
                },
                _active: {
                    borderColor: colors.green.default,
                },
                _readOnly: {
                    pointerEvents: 'none',
                },
                _disabled: {
                    cursor: 'not-allowed',
                },
                _invalid: {
                    borderColor: colors.orange['100'],
                },
            },
            addon: {
                backgroundColor: colors.beige.highlighted,
            },
        }
    }),
}

//
export const Input = defineMultiStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        size: 'sm',
        variant: 'filled',
    },
})
